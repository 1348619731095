import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      content
    }
  }
`;

const WpPageTemplate = ({ data }) => {
  const {
    wpPage: { title, content },
  } = data

  return (
    <Layout>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Layout>
  )
}

export default WpPageTemplate;

/* TODO: Stuck on*/
//run page query 